import { h } from 'preact'
import cn from 'classnames'

export default function buttonFactory({ defaults, icons, styles }) {
  const Button = ({
    autoHeight = defaults?.autoHeight,
    children,
    className,
    color = defaults?.color,
    disabled,
    href,
    fullWidth = defaults?.fullWidth,
    icon = defaults?.icon,
    invert = defaults?.invert,
    onClick,
    size = defaults?.size || 'medium',
    tabIndex,
    tag: Tag = href ? 'a' : 'button',
    title,
    type,
  }) => (
    <Tag
      className={cn(
        styles.base,
        styles[size],
        color && styles[color],
        autoHeight && styles.autoHeight,
        fullWidth && styles.fullWidth,
        invert && styles.invert,
        icon && styles.icon,
        className,
      )}
      disabled={disabled}
      href={href}
      onClick={onClick}
      tabIndex={tabIndex}
      title={title}
      type={type}
    >
      {icon ? <img className={styles.icon} src={icons[icon] || icon} /> : children}
    </Tag>
  )

  return Button
}

import { h } from 'preact'
import cn from 'classnames'
import s from './footer_navigation.module.scss'

const FooterNavigation = ({ routes, className, location }) => {
  return (
    <nav className={cn(s.base, className)}>
      <ul>
        {routes
          ?.filter(({ navigation }) => navigation !== false)
          .map(({ path, title }) => (
            <li
              className={cn(
                s.link,
                (location.pathname === path || (path !== '/' && location.pathname.startsWith(path))) && s.current,
              )}
            >
              <a key={path} href={path}>
                {title}
              </a>
            </li>
          ))}
      </ul>
    </nav>
  )
}

export default FooterNavigation

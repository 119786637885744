import { h } from 'preact'
import { useDispatch, useSelector } from '@bmp/store/preact'
import { useLoading } from '../contexts/loading.js'

import facilities from '../data/facilities.js'
import useIsomorphicEffect from '../util/use_isomorphic_effect.js'
import { useTranslate } from '../hooks/translate'
import { fetchEmployees, unloadEmployees } from '../store/employees_actions.js'

import Head from './head.jsx'

import Employee from './employee.jsx'
import GoogleMaps from './google_maps.jsx'
import ContactForm from './contact_form.jsx'
import OpeningHours from './opening_hours.jsx'

import s from './kontakt_page.module.scss'

const KontaktPage = () => {
  const translate = useTranslate()
  const loading = useLoading()
  const dispatch = useDispatch()
  const { employees, openingHours, organization } = useSelector(['employees', 'openingHours', 'organization'])

  useIsomorphicEffect(() => {
    const done = loading()

    if (!employees) dispatch(fetchEmployees()).then(done)

    return () => dispatch(unloadEmployees())
  }, [])

  return (
    <section className={s.base}>
      <Head>
        <title> : Kontakt</title>
      </Head>

      <GoogleMaps
        className={s.map}
        latitude={facilities.vallentuna.latitude}
        longitude={facilities.vallentuna.longitude}
      />

      <h1 className={s.pageHeading}>{translate('Kontakt')}</h1>

      {organization && (
        <section className={s.contactDetails}>
          <h1>{translate('Kontaktuppgifter')}</h1>

          <div className={s.row}>
            <div property='address' typeof='PostalAddress'>
              <strong>{organization.legalName}</strong>
              <br />
              <span property='streetAddress'>{organization.streetAddress}</span>
              <br />
              <span property='postalCode'>{organization.postalCode}</span>{' '}
              <span property='addressLocality'>{organization.locality}</span>
              <br />
              <span property='addressCountry'>{organization.country}</span>
            </div>
          </div>
          <p>
            <a className={s.phone} href={`tel:${organization.telephone.replace(/\D/g, '')}`} property='telephone'>
              {organization.telephone.replace(/ /g, '\xa0').replace(/-/g, '\u2011')}
            </a>
          </p>
          <p className={s.email} property='email'>
            <a href='mailto:{organization.email}'>{organization.email}</a>
          </p>
        </section>
      )}

      {openingHours && (
        <section className={s.openingHours}>
          <h1>{translate('Öppettider')}</h1>
          <OpeningHours openingHours={openingHours} />
        </section>
      )}

      <section className={s.employees}>
        <h1>{translate('Medarbetare')}</h1>

        <h2 class={s.heading}>Säljare</h2>
        {employees && employees.slice(0, 4).map((employee) => <Employee {...employee} className={s.employee} />)}
        <h2 class={s.heading}>Service/Logistik</h2>
        {employees && employees.slice(4, 6).map((employee) => <Employee {...employee} className={s.employee} />)}
        <h2 class={s.heading}>Administration</h2>
        {employees && employees.slice(-1).map((employee) => <Employee {...employee} email='' className={s.employee} />)}
      </section>

      <section className={s.contactForm}>
        <h1>Kontakta oss</h1>
        <ContactForm />
      </section>
    </section>
  )
}

export default KontaktPage

import cn from 'classnames'
import textareaFactory from '../../../shared/components/textarea_factory.jsx'

import formStyles from './form_elements.module.scss'
import textareaStyles from './textarea.module.scss'

export default textareaFactory({
  ...formStyles,
  element: cn(formStyles.element, textareaStyles.element),
  label: cn(formStyles.label, textareaStyles.label),
  statusIcon: cn(formStyles.statusIcon, textareaStyles.statusIcon),
})

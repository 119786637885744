import { h } from 'preact'
import cn from 'classnames'

import { useTranslate } from '../hooks/translate.js'

import s from './salj_din_bil_page.module.scss'
import sLayout from './layout.module.scss'
import SellYourCarForm from './sell_your_car_form.jsx'

const StartPage = () => {
  const translate = useTranslate()

  return (
    <section className={cn(sLayout.page, s.base)}>
      <header className={s.header}>
        <h1>{translate('Sälj din bil')}</h1>
        <p>
          Vi köper in bilar från alla bilmärken och hela landet. Genom oss blir försäljningen enkel och smidig, och du
          slipper lägga tid på administration och prutande. Fyll i fromuläret eller ring oss på{' '}
          <a href='tel:0852292700'>08&nbsp;522&nbsp;927&nbsp;00</a>.
        </p>
      </header>
      <SellYourCarForm className={s.form} />
      <section className={s.process}>
        <h1>Så går det till</h1>
        <ol role='list'>
          <li>
            <h2>Kontakt</h2>
            <p>
              Vi värderar och köper in din svensk sålda bil som är upp till 12 år gammal och gått upp till 20.000 mil.
            </p>

            <p>Du får svar inom 24 timmar eller näst kommande vardag via mail eller telefon.</p>

            <p>
              Du kan också nå oss på telefon på: <a href='tel:0852292700'>08 522 927 00</a>
            </p>
          </li>
          <li>
            <h2>Värdering av din bil</h2>
            <p>Vi värderar alla objekt manuellt i förhållande till dagens marknad.</p>

            <p>Passar din bil inte in hos oss så hjälper vi dig hitta en köpare via vårat kontaktnät.</p>
          </li>
          <li>
            <h2>Processen</h2>
            <p>
              När du accepterat värderingen så bokar vi upphämtning av din bil alternativt att du kommer in till oss och
              visar upp bilen.
            </p>

            <p>
              Så fort bilen är på plats stämmer vi av skicket enligt din beskrivning och avslutar affären med dig samma
              dag.
            </p>

            <p>Du ser pengarna för bilen samma dag alternativt inom 24 timmar beroende på bank.</p>
          </li>
          <li>
            <h2>Avslut</h2>
            <p>
              Vi ställer av bilen samt avslutar din befintliga försäkring. Om du har lån eller leasing på din bil så
              avslutas även detta och en avräkning görs mot avtal.
            </p>

            <p>Du får ett inköpsavtal antingen utskrivet eller på mail där allt vi kommit överens om är nedskrivet.</p>

            <p>
              Vid ytterligare frågor hänvisar vi dig till <a href='mailto:info@carson.nu'>info@carson.nu</a>.
            </p>
          </li>
        </ol>
      </section>
    </section>
  )
}

export default StartPage

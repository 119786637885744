import { h } from 'preact'
import cn from 'classnames'
import { useSelector } from '@bmp/store/preact'

import Head from './head.jsx'

import s from './tjanster_page.module.scss'
import sLayout from './layout.module.scss'

const ContentMainPage = () => {
  const { route } = useSelector('location')

  return (
    <section className={cn(sLayout.page, s.base)}>
      <Head>
        <title> : {route.title}</title>
      </Head>
      <div className={s.sections}>
        <header className={s.header}>
          <h1>Våra {route.title}</h1>
          <p>För att underlätta din bilvardag tillhandahåller vi ett flertal tjänster runt om själva bilhandlandet.</p>
        </header>
        {new Array(5).fill(0).map((_, i) => (
          <img src={`/img/tjanster-photo-0${i + 1}.jpg`} />
        ))}
        <section className={s.formedling}>
          <h1 style=''>Förmedling &amp; köp</h1>
          <p>Hos Carson kan du tryggt förmedla din bil.</p>
          <p>
            Vid förmedling så står du som kund registrerad ägare under säljprocessen. Vi tillhandahåller tjänster såsom
            reparation, rekonditionering, hjulskifte etc. Så snart bilen är såld, för vi över pengarna till ert konto,
            och vi löser eventuella lån som är bundna till bilen.
          </p>
          <p>
            Vi kan även köpa din bil. Vi ger oftast ett bud redan över telefon eller mail, men för att ge ett
            slutgiltigt bud behöver vi såklart testa bilen. Vi löser eventuella lån och vi betalar ut pengar samma dag.
            Tryggt, snabbt och enkelt.
          </p>
          <p>Vill du veta mer? Kontakta alexander@carson.nu.</p>
        </section>
        <section className={s.forsaljning}>
          <h1 style=''>Försäljning &amp; inbyte</h1>
          <p>
            Hos Carson finner ni ett brett sortiment av bilar i alla prisklasser, alla våra bilar är grundligt testade.
            I vårt testprogram ingår åtgärder av lackskavanker, service, besiktning, byte av ruta, trafiksäkerhetstest
            samt funktionstest. Vi följer branschens riktlinjer och följer alltid Allmänna Reklamationsnämndens beslut
            (ARN). Hos Carson är det trygga bilaffärer som är i fokus!
          </p>
          <p>
            Vid en affär hos oss på Carson så tar vi självklart emot din befintliga bil i inbyte, i de allra flesta fall
            så kan vi ge en relativt exakt bedömning av ett mellanpris redan över telefon eller mail.
          </p>
        </section>
        <section className={s.finansiering}>
          <h1 style=''>Finansiering</h1>
          <p>
            Vi hjälper dig att hitta en finansieringslösning som passar just dig, för företagskunder kan vi erbjuda
            finansiering och leasing från 0 kr kontant.
          </p>
        </section>
        <section className={s.service}>
          <h1 style=''>Service</h1>
          <p>
            Köper du bil hos oss på Carson så erbjuder vi att även i framtiden serva och ta hand om din bil. Det gör vi
            i vår egen verkstad. Vi erbjuder även fri lånebil och låga priser, samt att vi har mycket kompententa
            servicetekniker som bryr sig om din bil!
          </p>
        </section>
        <section className={s.forsakring}>
          <h1 style=''>Försäkring</h1>
          <p>
            Vi ser till att du är försäkrad. Köper du bil hos oss på Carson så hjälper vi till att försäkra din bil. Vi
            samarbetar med flera olika försäkringsbolag och ser till att din bil är försäkrad med en försäkring som
            motsvarar dina förväntningar och önskemål!
          </p>
        </section>
      </div>
    </section>
  )
}

export default ContentMainPage
